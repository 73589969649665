import React from 'react';
import { motion } from 'framer-motion';

interface WithRouterAnimationProps {
  initial?: any;
  in?: any;
  out?: any;
}

const WithRouterAnimation = (
  WrappedComponent: React.ComponentType<any>,
  pageAnimationParams: WithRouterAnimationProps
) => {
  const WithRouterAnimationComponent = (props: any) => {
    return (
      <motion.div
        style={{
          top: 0,
          width: '100%',
          height: '100%'
        }}
        initial={pageAnimationParams.initial}
        animate={pageAnimationParams.in}
        exit={pageAnimationParams.out}
        transition={pageAnimationParams}
      >
        {/* @ts-ignore */}
        <WrappedComponent {...props} />
      </motion.div>
    );
  };

  return WithRouterAnimationComponent;
};

export default WithRouterAnimation;
