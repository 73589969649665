import Slider, { Settings } from 'react-slick';
import styled from 'styled-components';

import Photo_1 from 'components/assets/images/MainPhoto1.jpg';
import Photo_2 from 'components/assets/images/MainPhoto2.jpg';
import Photo_3 from 'components/assets/images/MainPhoto3.jpg';
import Photo_4 from 'components/assets/images/MainPhoto4.jpg';
import theme from 'theme/theme';

const images = [Photo_1, Photo_2, Photo_3, Photo_4];

const HomePageSlider = () => {
  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    draggable: false,
    swipe: false,
  };

  return (
    <div style={{ pointerEvents: 'none' }}>
      {/* @ts-ignore */}
      <Slider {...settings}>
        {images.map((slide, i) => (
          <div key={i}>
            <SliderImage src={slide} alt='Slide' />
          </div>
        ))}
      </Slider>
    </div>
  );
};

const SliderImage = styled.img`
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
  object-position: 50% 50%;

  @media ${theme.deviceSizes.tablet} {
    width: 100%;
    height: auto;
    min-height: auto;
  }
`;

export default HomePageSlider;
