/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';

import ExpandMoreIcon from 'components/icons/ExpandMore.icon';

interface PolicyItemProps {
  title: string;
  overview: React.ReactNode;
  children: React.ReactNode;
  id?: string;
}

const PolicyItem: React.FC<PolicyItemProps> = ({
  title,
  overview,
  children,
  id,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const toggleExpanded = React.useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  return (
    <Accordion id={id}>
      <AccordionSummary
        onClick={toggleExpanded}
      >
        <AccordionSummaryContent>
          <Heading>{title}</Heading>
          <Text>{typeof overview === 'string' ? overview : <>{overview}</>}</Text>
        </AccordionSummaryContent>
        <StyledExpandMoreIcon expanded={expanded} />
      </AccordionSummary>
      <AccordionDetails expanded={expanded}>
        <div>
          {children}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

const StyledExpandMoreIcon = styled(ExpandMoreIcon)<{ expanded: boolean }>`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) ${props => props.expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const Accordion = styled.div`
  position: relative;
  padding: 0 16px;

  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
`;

const AccordionSummary = styled.div`
  cursor: pointer;

  padding: 20px 0;
  padding-right: 20px;
  position: relative;
  font-size: 30px;
  color: black;
`;

const AccordionDetails = styled.div<{ expanded: boolean }>`
  padding: ${props => props.expanded ? '10px 0 16px 0' : '0'};
  position: relative;
  background: white;
  max-height: ${props => props.expanded ? '1500px' : 0};
  font-size: 20px;
  text-align: justify;
  width: 100%;
  overflow: hidden;
  transition: 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const PrivacyPolicy: React.FC = () => {
  React.useEffect(() => {
    window.history.scrollRestoration = 'manual';

    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <MainHeading>Privacy Policy</MainHeading>
      <Text>Last modified: 09/08/2021</Text>
      <div>
        <PolicyItem
          title="(1) Introduction"
          overview="
            This Privacy Notice describes how zero5 collects and uses Personal Data about you
            through the use of our Websites, Applications, and through email, text, and other
            electronic communications between you and zero5.
          "
        >
          <Text>
            zero5, Inc. ("zero5" or "we" or "zero5") respects your privacy and is committed to protecting it
            through our compliance with this policy
          </Text>
          <Text>
            This Privacy Notice (our "<Strong>Privacy Notice</Strong>") describes the types of information we may collect
            from you or that you may provide when you visit the website zero5.co or subdomains thereof
            (each, a "<Strong>Website</Strong>") and/or when you use our mobile applications that you download from an
            official mobile application store, such as the Apple App Store® or the Google Play Store®
            (each, an "<Strong>Application</Strong>") and our practices for collecting, using, maintaining, protecting, and
            disclosing that information.
          </Text>
          <Text>
            This policy applies to information we collect:
          </Text>
          <List>
            <ListItem>
              on our Websites and Applications;
            </ListItem>
            <ListItem>
              in email, text, and other electronic messages between you and our Websites and/or
              Applications;
            </ListItem>
            <ListItem>
              when you visit a parking facility that uses our services, which may include
              information collected automatically through cameras and other recording devices as
              well as information manually entered by the operator of the parking facility; and
            </ListItem>
            <ListItem>
              when you interact with our advertising on third-party websites, applications, or
              services, if those advertisements include links to this policy.
            </ListItem>
          </List>
          <Text>
            It does not apply to information collected by:
          </Text>
          <List>
            <ListItem>
              through any other means, including on any other website or application operated by
              zero5 or any third-party or when you otherwise interact with us offline (other than
              when you visit a parking facility);
            </ListItem>
            <ListItem>
              us related to your or any other individual's employment or potential employment with
              us; or
            </ListItem>
            <ListItem>
              any third party, including through any application or content (including advertising)
              that may link to or be accessible from or on the Websites and Applications.
            </ListItem>
          </List>
          <Text>
            Please read this Privacy Notice carefully to understand our policies and practices regarding your
            information and how we will treat it. If you do not agree with our policies and practices, your
            choice is not to use our Websites or Applications. By accessing or using these Websites or
            Applications, you agree to this Privacy Notice. This Privacy Notice may change from time to
            time (see <StyledLink href="#changes-to-our-privacy-notice">Changes to Our Privacy Notice</StyledLink>).
            Your continued use of these Websites or Applications
            after we make changes is deemed to be acceptance of those changes, so please check this Privacy
            Notice periodically for updates.
          </Text>
        </PolicyItem>

        <PolicyItem
          title="(2) Children Under the Age of 13"
          overview="
            Our Websites and Applications are not intended for children under the age of 13 and
            children under the age of 13 are not permitted to use our Websites or Applications. We will
            remove any information about a child under the age of 13 if we become aware of it.
          "
        >
          <Text>
            Our Websites and Applications are not intended for children under 13 years of age. No one under
            age 13 may provide any information to or on the Websites or Applications. We do not knowingly
            collect Personal Data from children under 13. If you are under 13, do not use or provide any
            information on our Websites or Applications or on or through any of its features, register on the
            Websites or Applications, make any purchases through the Websites or Applications, use any
            interactive   or   public   comment   features   of   our   Websites   or  Applications,  or   provide   any
            information   about  yourself   to  us,  including   your  name,   address,  telephone  number,   email
            address, or any screen name or user name you may use. If we learn we have collected or received
            Personal Data from a child under 13 without verifiable parental consent, we will delete that
            information. If you believe we might have any information directly from a child under 13, please
            contact us at team@zero5.co.
          </Text>
        </PolicyItem>

        <PolicyItem
          title="(3) Information We Collect About You and How We Collect It"
          overview="
            We collect different types of information about you, including information that may directly
            identify you, information that is about you but individually does not personally identify you,
            and information that we combine with our other users. This includes information that we
            collect directly from you or through automated collection technologies.
          "
        >
          <Heading as="h3">(A) Generally</Heading>
          <Text>
            We collect several types of information from and about users of our Websites and Applications
            ("Personal Data"):
          </Text>
          <List>
            <ListItem>
              by which you may be personally identified, such as name, date of birth, postal address,
              e-mail address, telephone number, license plate number, vehicle identification number
              (“VIN”), images or video of your vehicles;
            </ListItem>
            <ListItem>
              if you are an employee of a parking facility that uses our services, we may collect
              information  that allows you to access  our Application or Website and manage the
              operations of the parking facility, such as your job title and employee ID
            </ListItem>
            <ListItem>
              that is about you but individually does not identify you, such as traffic data, location data,
              logs, referring/exit pages, date and time of your visit to our Websites or Applications,
              error information, clickstream data, and other communication data and the resources that
              you access and use on the Websites; and/or
            </ListItem>
            <ListItem>
              about your internet connection, the equipment you use to access our Websites and
              Applications, and usage details.
            </ListItem>
          </List>
          <Text>
            We collect this information:
          </Text>
          <List>
            <ListItem>
              directly from you when you provide it to us;
            </ListItem>
            <ListItem>
              automatically   as   you   navigate   through   the   Websites   or  Applications.   Information
              collected automatically may include usage details, IP addresses, information collected
              through cookies, and other tracking technologies; and
            </ListItem>
            <ListItem>
              from third parties, for example, the operators of the parking facilities that use our
              technology and services.
            </ListItem>
          </List>
          <Heading as="h3">(B) Information You Provide to Us</Heading>
          <Text>
            The information we collect on or through our Websites and Applications are:
          </Text>
          <List>
            <ListItem>
              information that you provide by filling in forms on our Websites or Applications. This
              includes   information   provided   at   the   time   of   registering   to   use   our   Websites   or
              Applications, subscribing to our service, or requesting further services. We may also ask
              you for information, when you enter a contest or promotion sponsored by us, and when
              you report a problem with our Websites or Applications;
            </ListItem>
            <ListItem>
              records and copies of your correspondence (including email addresses), if you contact us;
            </ListItem>
            <ListItem>
              your responses to surveys that we might ask you to complete for research purposes;
            </ListItem>
            <ListItem>
              details of transactions you carry out through our Websites or  Applications  and of the
              fulfillment of your orders. You may be required to provide financial information before
              placing an order through our Websites or Applications;
            </ListItem>
            <ListItem>
              your search queries on the Websites or Applications; and
            </ListItem>
            <ListItem>
              information you provide or is collected in order to deliver services to you.
            </ListItem>
          </List>
          <Heading as="h3">(C) Information We Collect Through Automatic Data Collection Technologies</Heading>
          <Text>
            As you navigate through and interact with our Websites or Applications, we may use automatic
            data collection technologies  to collect certain information about your equipment, browsing
            actions, and patterns:
          </Text>
          <List>
            <ListItem>
              details of your visits to or use of our Websites or Applications. We collect traffic data,
              location data, logs, referring/exit pages, date and time of your visit to our Websites and
              Applications, error information, clickstream data, and other communication data and the
              resources that you access and use on the Websites and Applications;
            </ListItem>
            <ListItem>
              Information about your computer or mobile device and internet connection, i.e., your IP
              address, operating system, and browser type; and
            </ListItem>
            <ListItem>
              Images of your vehicle and other information about your stay at a parking facility that
              uses our services, including an image of your vehicle and license plate, and your entry
              and exit times at the parking facility. We use this information to assist the parking facility
              in understanding the use of their facility as well as to allow you to use the "auto pay"
              feature of our services.
            </ListItem>
          </List>
          <Text>
            The information we collect automatically is statistical data may include Personal Data, or we
            may maintain it or associate it with Personal Data we collect in other ways or receive from third
            parties. It helps us to improve our Websites and Applications and to deliver a better and more
            personalized service by enabling us to:
          </Text>
          <List>
            <ListItem>
              estimate our audience size and usage patterns;
            </ListItem>
            <ListItem>
              store information about your preferences, allowing us to customize our Websites and
              Applications according to your individual interests;
            </ListItem>
            <ListItem>
              speed up your searches; and
            </ListItem>
            <ListItem>
              recognize you when you return to our Websites or Applications.
            </ListItem>
          </List>
          <Text>
            The technologies we use for this automatic data collection may include:
          </Text>
          <List>
            <ListItem>
              <Heading as="h4" inline>Cookies (or browser cookies).</Heading> A cookie is a small file placed on the memory of your
              computer or mobile device. Our Websites may use both session cookies (which expire
              once you close your browser) and persistent cookies (which stay on your computer until
              you delete them) to provide you with a more personal and interactive experience on our
              Websites. You can set your browser to refuse all or some browser cookies, or to alert you
              when cookies are being sent. However, if you do not consent to our use of cookies or
              select this setting you may be unable to access certain parts of our Websites. You can
              find more information about cookies at <StyledLink href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</StyledLink>.
            </ListItem>
            <ListItem>
              <Heading as="h4" inline>Session Cookies.</Heading> Our use of cookies may also include "session cookies." Each time you
              access the Website, a session cookie containing an encrypted, unique identifier is placed
              on your browser. These session cookies allow us to uniquely identify you when you use
              the Website and track which pages of the Website you access. Session cookies are
              required to use the Website
            </ListItem>
          </List>
          <Heading as="h3">(D) Information We Collect From Third Parties</Heading>
          <Text>
            We may also collect information from third parties, such as the operator of a parking facility at
            which you park your vehicle. This may include information about your vehicle and your stay at a
            parking facility, including when you use a valet to park your vehicle. We use this information to
            assist the parking facility in understanding the use of their facility, assist the facility in providing
            valet services, as well as to allow you to use the "auto pay" feature of our services.
          </Text>
        </PolicyItem>

        <PolicyItem
          title="(4) How We Use Your Information"
          overview={(
            <>
              <Text>
                We use your Personal Data for various purposes described below, including to:
              </Text>
              <List>
                <ListItem>
                  provide our Websites and Applications to you;
                </ListItem>
                <ListItem>
                  provide you with information and services you request from us;
                </ListItem>
                <ListItem>
                  enforce our rights arising from contracts;
                </ListItem>
                <ListItem>
                  manage your use of the parking facility, including when you valet park;
                </ListItem>
                <ListItem>
                  notify you about changes.
                </ListItem>
              </List>
            </>
          )}
        >
          <Text>
            We use information that we collect about you or that you provide to us, including any Personal Data:
          </Text>
          <List>
            <ListItem>
              to present our Websites and Applications and their contents to you;
            </ListItem>
            <ListItem>
              to provide you with information, products, or services that you request from us and to
              personalize  or develop those products or services  (including  for example,  auto-pay
              services that you may sign up for);
            </ListItem>
            <ListItem>
              to provide you with notices about your account;
            </ListItem>
            <ListItem>
              to carry out our obligations and enforce our rights arising from any contracts entered into
              between you and us, including for billing and collection;
            </ListItem>
            <ListItem>
              to notify you about changes to our Websites and Applications or any products or services
              we offer or provide through them;
            </ListItem>
            <ListItem>
              to allow you to participate in interactive features on our Websites and Applications;
            </ListItem>
            <ListItem>
              to maintain, customize, and secure your account;
            </ListItem>
            <ListItem>
              to help maintain the safety, security, and/or integrity of our business, website, products,
              services, databases, and other technology assets;
            </ListItem>
            <ListItem>
              to provide you with support and to respond to your inquiries (including to investigate and
              address concerns and monitor/improve responses)
            </ListItem>
            <ListItem>
              for internal testing, research, analysis, and product development, including to develop
              and improve our websites and application, and to develop, improve, or demonstrate our
              products and services including by training machine learning models;
            </ListItem>
            <ListItem>
              to undertake activities to verify or maintain the quality or safety of a service or device
              that is owned, manufactured, manufactured for, or controlled by us;
            </ListItem>
            <ListItem>
              to assist the operator of a parking facility that uses our services to help manage the
              parking facility, obtain payment for parking services, and improve to their services;
            </ListItem>
            <ListItem>
              to process your payment for parking or validation for parking;
            </ListItem>
            <ListItem>
              if you are an employee of a parking facility, so you may manage parking operations at the
              facility, including to enter and track information about valet tickets;
            </ListItem>
            <ListItem>
              in any other way we may describe when you provide the information;
            </ListItem>
            <ListItem>
              to fulfill any other purpose for which you provide it; and
            </ListItem>
            <ListItem>
              for any other purpose with your consent.
            </ListItem>
          </List>
        </PolicyItem>

        <PolicyItem
          title="(5) Disclosure of Your Information"
          overview={(
            <>
              <Text>
                We do not share, sell, or otherwise disclose your Personal Data for purposes other than
                those outlined in this Privacy Notice. We disclose your Personal Data to a few third
                parties, including:
              </Text>
              <List>
                <ListItem>
                  to the operators of the parking facilities in which you park your vehicle;
                </ListItem>
                <ListItem>
                  our third-party service providers that we use to support our business;
                </ListItem>
                <ListItem>
                  to a company we merge, acquire, or that buys us, or in the event of change in
                  structure of our company of any form;
                </ListItem>
                <ListItem>
                  to comply with our legal obligations;
                </ListItem>
                <ListItem>
                  to enforce our rights.
                </ListItem>
              </List>
            </>
          )}
        >
          <Text>
            We do not share, sell, or otherwise disclose your Personal Data for purposes other than those
            outlined in this Privacy Notice. However, we may disclose aggregated information about our
            users, and information that does not identify any individual, without restriction.
          </Text>
          <Text>
            We may disclose Personal Data that we collect or you provide as described in this Privacy
            Notice:
          </Text>
          <List>
            <ListItem>
              to the operator of the parking facility that you park your vehicle in;
            </ListItem>
            <ListItem>
              to contractors, service providers, and other third parties we use to support our business.
              Our payment processors' privacy policies may be found at <StyledLink href="http://stripe.com/us/privacy">http://stripe.com/us/privacy</StyledLink>;
            </ListItem>
            <ListItem>
              to a potential or actual buyer or other successor in the event of a planned or actual
              merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of
              some or all of zero5's assets, whether as a going concern or as part of bankruptcy,
              liquidation, or similar proceeding, in which Personal Data held by zero5 about our
              Websites' and/or Applications' users is among the assets transferred;
            </ListItem>
            <ListItem>
              to fulfill the purpose for which you provide it;
            </ListItem>
            <ListItem>
              for any other purpose disclosed by us when you provide the information; and
            </ListItem>
            <ListItem>
              with your consent.
            </ListItem>
          </List>
          <Text>
            We may also disclose your Personal Data:
          </Text>
          <List>
            <ListItem>
              to comply with any court order, law, or legal process, including to respond to any
              government or regulatory request;
            </ListItem>
            <ListItem>
              to enforce or apply our terms of use and other agreements, including for billing and
              collection purposes; and
            </ListItem>
            <ListItem>
              if we believe disclosure is necessary or appropriate to protect the rights, property, or
              safety of zero5, our customers, or others. This includes exchanging information with
              other companies and organizations for the purposes of fraud protection and credit risk
              reduction.
            </ListItem>
          </List>
        </PolicyItem>

        <PolicyItem
          title="(6) Choices About How We Use and Disclose Your Information"
          overview="
            We offer you choices on how you can opt out of our use of tracking technology, disclosure
            of your Personal Data for third-parties to advertise to you, our advertising to you, and other
            targeted advertising.
          "
        >
          <Text>
            We do not control the collection and use of your information collected by third parties described
            above   in  <StyledLink href="#disclosure-of-your-information">Disclosure   of   Your   Information</StyledLink>.
            When   possible,   these   organizations   are   under
            contractual obligations to use this data only for providing the services to us and to maintain this
            information strictly confidential. These third parties may, however, aggregate the information
            they collect with information from their other customers for their own purposes.
          </Text>
          <Text>
            In addition, we strive to provide you with choices regarding the Personal Data you provide to us.
            We have created mechanisms to provide you with control over your Personal Data:
          </Text>
          <List>
            <ListItem>
              <Heading as="h4" inline>Tracking Technologies.</Heading> You can set your browser to refuse all or some browser cookies,
              or to alert you when cookies are being sent. If you disable or refuse cookies, please note
              that some parts of our Websites may then be inaccessible or not function properly.
            </ListItem>
          </List>
        </PolicyItem>

        <PolicyItem
          title="(7) Accessing, Correcting, and Deleting Your Information"
          overview="
            You may review and change your Personal Data by logging into the Websites or
            Applications and visiting your account preferences page or by contacting us at any time.
            You may also review, change, and request deletion of your Personal Data offline by
            contacting us through the contact information below.
          "
        >
          <Text>
            You can review and change some of your Personal Data by logging into the Websites or
            Applications and visiting your account profile page. You may also contact us through any of the
            contact information below to request access to, correct or delete some types of Personal Data that
            you have provided to us. We cannot delete your Personal Data except by also deleting your user
            account. We may not always be able to accommodate a request to delete or change information,
            including if we believe the deletion or change would violate any law or legal requirement or
            cause the information to be incorrect. If you are an employee of a parking facility, you may need
            to make your request through the operator of the parking facility.
          </Text>
          <Text>
            California residents may have additional rights and choices related to the use of their Personal
            Data. Please see State-Specific Privacy Rights for more information.
          </Text>
        </PolicyItem>

        <PolicyItem
          title="(8) State-Specific Privacy Rights"
          overview="
            California law provides you with the right to obtain more information about our use of an
            Automatic License Plate Recognition System, which is provided in our ALPRS Privacy
            Addendum.
          "
        >
          <Heading as="h3">Your California Privacy Rights</Heading>
          <Text>
            In addition, California residents are entitled to certain information about our use of Automated
            License Plate Recognition Systems ("ALPR System") as described in the California ALPR
            System Addendum.
          </Text>
        </PolicyItem>

        <PolicyItem
          title="(9) Do Not Track Signals"
          overview="
            We may use automated data collection technologies to track you across websites. We
            currently do not honor do-not-track signals that may be sent by some browsers.
          "
        >
          <Text>
            We also may use automated data collection technologies to collect information about your online
            activities over time and across third-party websites or other online services (behavioral tracking).
            Some web browsers permit you to broadcast a signal to websites and online services indicating a
            preference that they "do not track" your online activities. At this time, we do not honor such
            signals and we do not modify what information we collect or how we use that information based
            upon whether such a signal is broadcast or received by us.
          </Text>
        </PolicyItem>

        <PolicyItem
          title="(10) Data Security"
          overview="
            Information transmitted over the Internet is not completely secure, but we do our best to
            protect your Personal Data. You can help protect your Personal Data and other information
            by keeping your password(s) to our Websites and Applications confidential.
          "
        >
          <Text>
            We have implemented measures designed to secure your Personal Data from accidental loss and
            from unauthorized access, use, alteration, and disclosure. Any payment transactions will be
            encrypted.
          </Text>
          <Text>
            The safety and security of your information also depends on you. Where we have given you (or
            where you have chosen) a password for access to certain parts of our Websites or Applications,
            you are responsible for keeping this password confidential. We ask you not to share your
            password with anyone and to use a password on our Websites and Applications that is different
            from passwords you use on other websites and applications.
          </Text>
          <Text>
            Unfortunately,   the   transmission   of   information   via   the   internet   is   not   completely   secure.
            Although we do our best to protect your Personal Data, we cannot guarantee the security of your
            Personal Data transmitted to our Websites or Applications. Any transmission of Personal Data is
            at your own risk. We are not responsible for circumvention of any privacy settings or security
            measures deployed on the Websites or Applications.
          </Text>
        </PolicyItem>

        <PolicyItem
          id="#changes-to-our-privacy-notice"
          title="(11) Changes to Our Privacy Notice"
          overview="
            We will post any changes to our Privacy Notice on our Websites. If we make material
            changes to our Privacy Notice, we may notify you of such changes through your contact
            information and invite you to review (and accept, if necessary) the changes.
          "
        >
          <Text>
            We may change this Privacy Notice at any time. It is our policy to post any changes we make to
            our Privacy Notice on this page or on the main screen or as a pop-up in our Application. If we
            make material changes to how we treat our users' Personal Data, we will notify you by email to
            the email address specified in your account. The date this Privacy Notice was last revised is
            identified at the top of the page. You are responsible for ensuring we have an up-to-date active
            and   deliverable   email   address   for   you,   and   for   periodically   visiting   our   Websites   and/or
            Applications and this Privacy Notice to check for any changes.
          </Text>
        </PolicyItem>

        <PolicyItem
          title="(12) Contact Information"
          overview="
            You may contact us through the contact information below.
          "
        >
          <Text>
            If you have any questions, concerns, complaints or suggestions regarding our Privacy Notice,
            have any requests related to your Personal Data pursuant to applicable laws, or otherwise need to
            contact us, you must contact us at the contact information below or through the "Contact" page
            on our Websites.
          </Text>
          <Heading as="h3">To Contact zero5:</Heading>
          <Text>
            zero5
            <br />
            3 E. 3rd Ave
            <br />
            Suite 221
            <br />
            San Mateo, CA 94401
            <br />
            USA
            <br />
            <StyledLink href="mailto:team@zero5.co">team@zero5.co</StyledLink>
          </Text>
        </PolicyItem>

        <PolicyItem
          title="(13) California Automated License Plate Recognition System Addendum"
          overview=""
        >
          <Text>Effective Date: 09/08/2021</Text>
          <Text>
            This  California  Automated  License  Plate  Recognition  System  Addendum  (the  "Addendum")
            governs the ALPR System operated by zero5, and applies to our employees, customers, and camera
            affiliates who have access to or use our ALPR Systems. Our services may include the use of an
            Automated License Plate Recognition System ("ALPRS" or "ALPR System") when deployed at a parking
            facility by that facility's operator. Our ALPR System contains a searchable computerized database
            resulting  from  the  operation  of  one  or  more  mobile  or  fixed  cameras  combined  with
            computer  algorithms  to  read  and  convert  images  of  registration  plates  and  the
            characters  they contain into computer-readable data. The information contained in the system
            includes images of license  plates,  plus  the  date,  time  and  location  when  the  images
            were  collected,  and  the  license plate characters and numbers associated with the image.
          </Text>
          <Text>
            The images stored in the system are collected from areas visible to the public where there is no
            reasonable expectation of privacy. The data collected by the ALPR System and stored in our
            system may contain Personal Data (as defined in our Privacy Notice), such as the information
            which relates the license plate image to the driver when you provide us this information.
          </Text>
          <Text>
            PII contained in vehicle registration information is protected by federal law (Driver's Privacy
            Protection Act, Title 18, US Code, Section 2721 et seq.) and state laws. Law enforcement and
            other users may have access to vehicle registration information, and other sources of PII, which
            they may correlate with data collected and stored in the ALPR System to create vehicle hotlists
            in accordance with the above referenced federal law.
          </Text>
          <Heading as="h3">(A) Use and Collection of ALPR System data</Heading>
          <Text>
            zero5 authorizes collection of ALPR System data for use by zero5, the parking facility operators,
            and other customers of zero5's products and services consistent with this policy. The authorized
            uses of the ALPR system are:
          </Text>
          <List>
            <ListItem>
              By zero5 and the operator of a parking facility for parking enforcement and management,
              including to identify or ascertain the location and identity of a specific vehicle when there
              is a legitimate commercial interest in doing so (for example, for billing and collections
              purposes or to prepare a vehicle for pickup from the parking facility);
            </ListItem>
            <ListItem>
              By law enforcement agencies for law enforcement purposes.
            </ListItem>
            <ListItem>
              By zero5 to make data collected by the ALPR System available to customers and law
              enforcement agencies for the purposes described above, to provide market research
              information to parking facility operators and other similar parties based on aggregated
              data collected by the ALPR System data, and to debug and improve our ALPR System
              and other products and services.
            </ListItem>
          </List>
          <Text>
            Some examples of the permitted users of the ALPR System include:
          </Text>
          <List>
            <ListItem>
              zero5's personnel;
            </ListItem>
            <ListItem>
              The operator of the parking facility;
            </ListItem>
            <ListItem>
              Law enforcement personnel; and
            </ListItem>
            <ListItem>
              Entities to which information collected by the ALPR System or our Applications maybe
              disclosed as permitted under the Driver's Privacy Protection Act, 18 U.S.C. 2721 et seq.
            </ListItem>
          </List>
          <Text>
            We do not make the ALPR System or the data collected or stored by it available to individuals
            for personal, non-commercial purposes or for any purposes not described above.
          </Text>
          <Heading as="h3">(B) Users and Training</Heading>
          <Text>
            zero5 personnel are only permitted to use the ALPR System for the following limited purposes:
            (a) to demonstrate the system to parking facility operators who use our products and services, or
            who are interested in potentially using our products and services; (b) provide customer support,
            (c) to identify and repair errors that impair existing intended functionality in our products and
            services; (d) for internal testing, research, analysis, and product development, including to
            develop and improve the ALPR System and our other products and services; and (d) collect data
            for purposes authorized by this policy. All authorized personnel that use or access information
            collected from the ALPR System are required to read and understand our training manuals and
            other documentation for the ALPR System. Additionally, we require that all personnel of the
            operators of the parking facilities to attest that they have read and understood all applicable
            training materials provided either by zero5 or the parking facility operator.
          </Text>
          <Heading as="h3">(C) Monitoring and Compliance</Heading>
          <Text>
            The ALPR System sends status updates to zero5, and these status updates are monitored 24 hours
            a day for information anomalies and system disruptions. zero5 also monitors the information
            collected by the ALPR System to ensure the accuracy of charges to drivers using the parking
            facility, and zero5 promptly investigates any reports of incorrect ALPR System data and rectifies
            such data as appropriate.
          </Text>
          <Text>
            zero5 maintains usage logs, and periodically audit such logs, to ensure the security of our data
            assets and compliance with this policy. The usage logs include the following information:
          </Text>
          <List>
            <ListItem>
              The date and time data in the ALPR System is accessed.
            </ListItem>
            <ListItem>
              The IP address and other information about the device from which the ALPR System is
              accessed, such as the version of the zero5 Application, and make and model number of
              the device.
            </ListItem>
            <ListItem>
              The license plate number or other data elements used to query the system.
            </ListItem>
            <ListItem>
              The  username  of the person who accessed the  information  and, as  applicable,  the
              organization or entity with whom the person is affiliated.
            </ListItem>
            <ListItem>
              The purpose for accessing the information.
            </ListItem>
          </List>
          <Heading as="h3">(D) Sale, Sharing or Transfer of ALPR System Data</Heading>
          <Text>
            zero5 collects data for commercial purposes. We do not collect ALPR System data on behalf of
            law enforcement agencies, and we do not share, sell, or transfer ALPR System data to other
            third-parties other than as described in this Addendum. We license the information from the
            ALPR System to parking facility operators and other similar entities solely for the purposes
            described in this Addendum. We share the results of specific queries for use by its customers
            consistent with this Addendum and pursuant to the parking facility operator's license agreement.
            All parking facility operators and other appropriate users are required to establish an account
            pursuant to an agreement with us that limits the use of the ALPR System data for authorized
            purposes only. We will also disclose the ALPR System data for the purposes described in our
            Privacy Notice, including to respond to law enforcement requests and as required by applicable
            law, court order, or governmental regulations.
          </Text>
          <Heading as="h3">(E) Custodian or Owner of the ALPR System</Heading>
          <Text>
            zero5 is the custodian and owner of the ALPR System and is responsible for implementing this
            Addendum.
          </Text>
          <Heading as="h3">(F) Accuracy the Data Collected and Processed by the ALPR System</Heading>
          <Text>
            The collection data by the ALPR System is automated so that the license plate images, and the
            details of when they are collected, are included in the system without review, along with the
            computer translation of the license plate number. Although very infrequent, the license plate
            translation is sometimes inaccurate or incomplete. To avoid mistaken use of ALPR System data,
            zero5 recommends that you, as well as our parking facility operators customers, confirm the
            computer translation before taking any action as a result of information automatically collected
            and processed by the ALPR System. zero5 and/or the parking facility operator may correct errors
            in translation to license plate numbers and other database errors when identified.
          </Text>
          <Heading as="h3">(G) Retention</Heading>
          <Text>
            The company retains the data collected by the ALPR System as long as it has commercial value,
            which is generally one (1) year. The company periodically evaluates the use of historical data
            collected and stored by the ALPR System to determine if the cost to maintain the data exceeds its
            value, and may adjust its retention period. We may keep the data collected by the ALPR System
            for a longer period of time as follows: (a) as long as necessary to comply with any legal
            requirement (including any legal hold; (b) on our backup and disaster recovery systems in
            accordance with our backup and disaster recover policies and procedures; (c) for as long as
            necessary to protect our legal interests or otherwise pursue our legal rights and remedies; (d) if
            reasonably requested by the parking facility operator, for as long as they reasonably request. Note
            that we may retain any data collected from the ALPR System that has been aggregated or
            otherwise rendered anonymous in a manner that you are no longer identifiable for an indefinite
            period of time.
          </Text>
          <Heading as="h3">(H) Revisions to This Privacy Notice</Heading>
          <Text>
            zero5 reserves the right to amend this Addendum at our discretion and at any time. When we
            make changes to this Addendum, we will post the updated Addendum on our Website and in our
            Application and update the Addendum's effective date. Your continued use of our products and
            services following the posting of changes constitutes your acceptance of such changes.
          </Text>
        </PolicyItem>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-weight: 300;
`;

const AccordionSummaryContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainHeading = styled.h1`
  margin: 0 0 30px 0;
  text-align: center;
  font-size: 1.846rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.167;
`;

const Text = styled.p`
  margin: 0 0 10px 0;
  font-size: 12px;

  &:last-child {
    margin: 0;
  }
`;

const Strong = styled.strong`
  font-weight: bold;
`;

const Heading = styled.h2<{ inline?: boolean; }>`
  display: ${({ inline }) => (inline ? 'inline' : 'block')};
  margin: 0 0 10px 0;
  font-size: 12px;
  font-weight: 700;
`;

const List = styled.ul`
  margin: 0 0 10px 0;
  padding: 0 0 0 15px;
  list-style: disc;
  font-size: 10px;

  &:last-child {
    margin: 0;
  }
`;

const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const StyledLink = styled.a`
  color: #386344;
  font-size: 12px;
`;

export default PrivacyPolicy;
