import React, { useMemo } from 'react';

import WithRouterAnimation from 'utils/HOC/WithRouterAnimation';
import { viewportBreakpoints } from 'utils/constants';
import useViewport from 'utils/hooks/useViewport';

import { S } from './SLogin';

interface LoginProps {
  initial?: any;
  in?: any;
  out?: any;
}

const Login: React.FC<LoginProps> = () => {
  const { width } = useViewport();

  const isMobile = useMemo(
    () => width <= parseInt(viewportBreakpoints.tablet),
    [width]
  );

  const left = useMemo(() => width / 2, [width]);

  return (
    <S.Container>
      <S.Label
        fontSize='fz24'
        text='Login Options'
        fontWeight='300'
        lineHeight={isMobile ? '30' : '38'}
        left={left}
      />
      <S.LoginOptions>
        {/* Admin Login Option */}
        <S.LoginBox>
          <S.LoginTitle>Admin Login</S.LoginTitle>
          <S.LoginDescription>Access the admin dashboard for complete system control and configuration</S.LoginDescription>
          <S.LoginButton href="https://admin.zero5.co">
            Sign In
          </S.LoginButton>
        </S.LoginBox>

        {/* Tenant Login Option */}
        <S.LoginBox>
          <S.LoginTitle>Tenant Login</S.LoginTitle>
          <S.LoginDescription>Manage your organization's settings, users, and permissions</S.LoginDescription>
          <S.LoginButton href="https://tenant.zero5.co">
            Sign In
          </S.LoginButton>
        </S.LoginBox>

        {/* User Login Option */}
        <S.LoginBox>
          <S.LoginTitle>User Login</S.LoginTitle>
          <S.LoginDescription>Access your personal dashboard and applications</S.LoginDescription>
          <S.LoginButton href="https://app.zero5.co">
            Sign In
          </S.LoginButton>
        </S.LoginBox>

        <S.FooterText>
          Don't have an account? <a href="mailto:support@zero5.co">Contact Us</a>
        </S.FooterText>
      </S.LoginOptions>
    </S.Container>
  );
};

export default WithRouterAnimation(Login, {
  initial: {
    opacity: 0,
    x: '100%',
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    x: '-100%',
  },
});
