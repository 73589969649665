import { useCallback, useMemo, useRef, useState } from 'react';
import Slider from 'react-slick';

import ArrowIcon from 'components/icons/Arrow.icon';
import ArrowLeft from 'components/icons/Arrow-Left.icon';

import theme from 'theme/theme';
import {heightMobileBreakpoints, viewportBreakpoints} from 'utils/constants';
import useViewport from 'utils/hooks/useViewport';

import CheckImg from '../../../assets/Check.png';
import ClockImg from '../../../assets/Clock.png';
import HeartImg from '../../../assets/Heart.png';
import Card from '../Card/Card';
import { S } from './SSlider';
import {ORIENTATION} from 'utils/enums';

export type CardProps = {
  content: string | JSX.Element;
  imgLink: string;
  selected?: boolean;
  headTitle: string;
  headSubTitle: string;
  i?: number;
};

export const slidersContent: CardProps[] = [
  {
    headTitle: 'Simple. Effortless.',
    headSubTitle: 'Oh. So. Simple.',
    content: (
      <>
        <S.P>
          We track every activity within your parking areas to understand vehicle and user behaviour, 
          to enhance the security, and to combat parking garage traffic.
        </S.P>
        <S.P>
          Our smart solution analyzes parking availability and directs drivers to an open parking spot.
        </S.P>
        {/* <S.P>
          Also, continuous monitoring of the property improves safety for the
          clients.
        </S.P> */}
      </>
    ),
    imgLink: CheckImg,
  },
  {
    headTitle: 'Unmatched support',
    headSubTitle: '24 hours / 7 days / 365 days',
    content: (
      <>
        <S.P>
          Run a successful parking garage with service that allows you to monitor your property 24/7.
        </S.P>
        <S.P>
          Built on cutting edge technology, you will be able to monitor all aspects of your business any time, any where.
        </S.P>
      </>
    ),    
    // content: `Run a successful parking garage with service that allows you to monitor your property 24/7. 
    //   all built on the back of cutting-edge technology. 
    //   Successfully run a parking garage with our support solutions all built on the back of cutting-edge technology. 
    //   Whether you experience hardware malfunctions or payment problems, we'll jump in and get you back in business.
    // `,
    imgLink: ClockImg,
  },
  {
    headTitle: 'Ticketless and contactless',
    headSubTitle: 'Amazon Go for parking',
    content: (
      <>
        <S.P>
          We digitize and automate entire parking operation. We replace outdated ticketing machiens with ML/AI technology.
        </S.P>
        <S.P>
          Our number one focus is to make parking easy for drivers and property managers. All the while automatically gathering and analyzing real-time data to benefit your business.
        </S.P>
      </>
    ),       
    // content: `We digitize the entire parking space. 
    //   We replace outdated ticketing machines with our computer vision technology. 
    //   Our number one focus is to make parking easy for drivers and property managers. 
    //   All the while we feed real-time data directly to our clients' smart devices.
    // `,
    imgLink: HeartImg,
  },
];

const NSlider = () => {
  const { width, height, orientation } = useViewport();
  let sliderRef: any = {};
  const secondSlideRef = useRef<HTMLDivElement>(null);

  const isIOS = useMemo(
    () => navigator.userAgent.includes('iPhone OS'),
    [navigator]
  );

  const isMobile = useMemo(
    () => width <= parseInt(viewportBreakpoints.tablet),
    [width]
  );

  const isLandscape = useMemo(
    () => (width <= parseInt(viewportBreakpoints.laptop) && (height < heightMobileBreakpoints && orientation === ORIENTATION.landscape)),
    [width, orientation, height]
  );

  const [currentSlide, setCurrentSlide] = useState<number>(isMobile ? 0 : 1);


  const isLaptop = useMemo(
    () => width <= parseInt(viewportBreakpoints.laptop),
    [width]
  );

  const isLaptop1200 = useMemo(
    () => width <= parseInt(viewportBreakpoints.laptopL),
    [width]
  );

  const isDesktop = useMemo(
    () => width <= parseInt(viewportBreakpoints.desktopS),
    [width]
  );

  const isDesktopL = useMemo(
    () => width <= parseInt(viewportBreakpoints.desktopL),
    [width]
  );

  const left = useMemo(() => width / 2, [width]);

  const settings = {
    rows: 1,
    className: 'solution',
    arrows: false,
    slidesPerRow: 1,
    initialSlide: (isLaptop || isLandscape) ? 0 : 1,
    centerMode: !isMobile,
    slidesToShow: (isLaptop || isLandscape) ? 1 : 2,
    slideToScroll: 1,
    centerPadding: isMobile
      ? 'none':
      isLandscape
      ? '20px'
      : isLaptop
      ? '50px'
      : isLaptop1200
      ? '70px'
      : isDesktop
      ? '130px'
      : isDesktopL
      ? '200px'
      : '220px',

    beforeChange: (prev: number, next: number) => {
      setCurrentSlide(next);
    },
  };

  const handleNext = useCallback(() => {
    sliderRef?.slickPrev();
  }, []);

  const handlePrev = useCallback(() => {
    sliderRef?.slickNext();
  }, []);



  return (
    <S.Container>
      {!isMobile && (
        <S.ButtonRow leftPosition={Math.trunc(left)}>
          <S.RoundBtn onClick={handleNext}>
            <ArrowLeft color={theme.colors.Yellow} />
          </S.RoundBtn>

          <S.RoundBtn style={{ marginLeft: 13 }} onClick={handlePrev}>
            <ArrowIcon width={12} height={20} color={theme.colors.Yellow} />
          </S.RoundBtn>
        </S.ButtonRow>
      )}

      {/* @ts-ignore */}
      <Slider
        {...settings}
        ref={(ref: any) => {
          if (ref) {
            sliderRef = ref;
          }
        }}
      >
        {slidersContent.map((el, i) => {
          return (
            <div key={i} ref={i === 0 ? secondSlideRef : null}>
              <Card
                i={i}
                selected={
                  i ===
                  (isLaptop
                    ? currentSlide
                    : currentSlide === 0
                    ? 2
                    : currentSlide - 1)
                }
                key={i}
                {...el}
              />
            </div>
          );
        })}
      </Slider>
      {isMobile && (
        <S.CounterContainer style={{marginTop: isIOS ? '0px' : '-3px'}}>
          {slidersContent.map((_, i: number) => (
            <S.CounterLine key={i} active={i == currentSlide} />
          ))}
        </S.CounterContainer>
      )}

      {isMobile && (
        <S.ButtonRow leftPosition={0}>
          <S.RoundBtn onClick={handleNext}>
            <ArrowLeft width={12} height={20} color={theme.colors.Yellow} />
          </S.RoundBtn>

          <S.RoundBtn style={{ marginLeft: 13 }} onClick={handlePrev}>
            <ArrowIcon width={12} height={20} color={theme.colors.Yellow} />
          </S.RoundBtn>
        </S.ButtonRow>
      )}
    </S.Container>
  );
};

export default NSlider;
