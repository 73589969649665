import { ReactNode } from 'react';
import styled from 'styled-components';

import Typography from 'components/Typography/Typography';
import theme from 'theme/theme';
import { getRem } from 'utils/functions';

type PageLabelProps = {
  label?: string;
  className?: string;
  CustomComponent?: ReactNode;
};

const PageLabel = ({ label, className, CustomComponent }: PageLabelProps) => {
  return (
    <Container className={className}>
      {CustomComponent ? (
        <>{CustomComponent}</>
      ) : (
        <Typography
          lineHeight={30}
          fontSize='fz24'
          text={label || ''}
          fontWeight={300}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: max-content;
  height: max-content;
  white-space: nowrap;
  margin-top: ${getRem(218)};
  padding-left: ${(props) => props.theme.spacers.primaryLeft};
  border-bottom: 1px solid ${(props) => props.theme.colors.Black} !important;

  @media ${theme.deviceSizes.laptopL} {
    padding-left: 5%;
  }

  @media ${theme.deviceSizes.tablet} {
    padding-left: ${getRem(77)};
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    margin-top: ${getRem(90)};
    padding-left: ${getRem(20)};
  }
`;

export default PageLabel;
