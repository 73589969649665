import styled from 'styled-components';

import Button from 'components/Button/Button';
import Typography from 'components/Typography/Typography';
import { getRem } from 'utils/functions';
import theme from 'theme/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: ${getRem(265)};
  background-color: ${(props) => props.theme.colors.White};

  @media ${theme.deviceSizes.tablet} {
    padding-top: ${getRem(152)};
  }
`;

const Label = styled(Typography)<{ left: number }>`
  left: 0;
  text-align: right;
  position: absolute;
  overflow: hidden;
  top: ${getRem(205)};
  width: ${(props) => getRem(props.left - 210)};
  color: ${(props) => props.theme.colors.Black};
  border-bottom: 1px solid ${(props) => props.theme.colors.MainGreen};

  @media ${theme.deviceSizes.tablet} {
    width: ${getRem(200)};
    text-align: right;
    top: ${getRem(89)};
  }
`;

const FormWrapper = styled.form`
  width: ${getRem(661)};
  height: ${getRem(474)};
  padding: ${getRem(62)} ${getRem(85)} 0 ${getRem(85)};
  background-color: ${(props) => props.theme.colors.MainGreen};
  margin-top: ${getRem(44)};
  margin-bottom: ${getRem(29)};

  @media ${theme.deviceSizes.tablet} {
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding: ${getRem(62)} ${getRem(20)} 0 ${getRem(20)};
    margin-top: initial;
    margin-bottom: ${getRem(27)};
  }
`;

const BottomContent = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${getRem(71)};

  @media ${theme.deviceSizes.tablet} {
    flex-direction: column;
    margin-top: ${getRem(38)};
    width: ${getRem(280)}
  }
`;

const SubmitBtn = styled(Button)`
  flex-shrink: 0;
  padding: 0 ${getRem(15)} 0 ${getRem(40)};
  justify-content: space-between;
  width: ${getRem(289)};
  height: ${getRem(57)};

  @media ${theme.deviceSizes.tablet} {
    width: ${getRem(258)};
    height: ${getRem(50)};
    margin-bottom: ${getRem(28)};
    padding-left: ${getRem(20)};
  }

  &:hover {
    &::before {
      background-color: ${(props) => props.theme.colors.Black};
    }
  }
`;

const TextContainer = styled.div`
  font-family: Roboto, sans-serif;
  margin-left: ${getRem(50)};

  @media ${theme.deviceSizes.tablet} {
    margin: 0;
  }

  & > * {
    font-size: 12px;
    line-height: 19px;
    font-weight: 300;
    color: ${(props) => props.theme.colors.White};
  }

  & > a {
    color: ${(props) => props.theme.colors.LightGreen};
  }
`;

const PasswordInput = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-top: ${getRem(36)};

  & > a {
    opacity: 0.7;
    font-size: 14px;
    position: absolute;
    right: ${getRem(30)};
    font-weight: lighter;
    color: ${(props) => props.theme.colors.White};
  }

  @media ${theme.deviceSizes.tablet} {
    margin-top: ${getRem(31)};
  }
`;

const FogotPasswordLink = styled.a`
  font-weight: 300 !important;
  line-height: 29.5px;
  @media ${theme.deviceSizes.tablet} {
    display: none
  }
`;

const ContactUsBlock = styled.div`
  display: flex;
  height: ${getRem(139)};
  align-items: start;
  justify-content: center;

  & > * {
    font-size: 18px;
    line-height: 26px;
  }

  & > a {
    color: ${(props) => props.theme.colors.Yellow};
  }
`;

// New login options styling
const LoginOptionsContainer = styled.div`
  width: ${getRem(661)};
  margin-top: ${getRem(44)};
  margin-bottom: ${getRem(29)};
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media ${theme.deviceSizes.tablet} {
    width: 100vw;
    padding: 0 ${getRem(20)};
    margin-bottom: ${getRem(27)};
  }
`;

const LoginBox = styled.div`
  width: 100%;
  padding: ${getRem(40)};
  background-color: ${(props) => props.theme.colors.MainGreen};
  margin-bottom: ${getRem(20)};
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.colors.White};
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  }
`;

const LoginTitle = styled.h2`
  font-size: ${getRem(24)};
  font-weight: 300;
  margin-bottom: ${getRem(20)};
  color: ${(props) => props.theme.colors.White};
`;

const LoginDescription = styled.p`
  font-size: ${getRem(16)};
  font-weight: 400;
  margin-bottom: ${getRem(30)};
  text-align: center;
  color: ${(props) => props.theme.colors.LightGreen};
`;

const LoginButton = styled.a`
  background-color: ${(props) => props.theme.colors.Yellow};
  color: ${(props) => props.theme.colors.Black};
  border: none;
  height: ${getRem(57)};
  width: ${getRem(289)};
  font-size: ${getRem(18)};
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${getRem(15)} 0 ${getRem(40)};
  position: relative;
  transition: background-color 0.3s;
  text-decoration: none;

  &:hover {
    background-color: ${(props) => props.theme.colors.Black};
    color: ${(props) => props.theme.colors.White};
  }
  
  &::after {
    content: "→";
    font-size: ${getRem(24)};
    margin-left: ${getRem(15)};
  }
  
  @media ${theme.deviceSizes.tablet} {
    width: ${getRem(258)};
    height: ${getRem(50)};
    padding-left: ${getRem(20)};
  }
`;

const FooterText = styled.p`
  text-align: center;
  color: ${(props) => props.theme.colors.Black};
  font-size: ${getRem(18)};
  line-height: ${getRem(26)};
  margin-top: ${getRem(70)};
  margin-bottom: ${getRem(40)};

  a {
    color: ${(props) => props.theme.colors.Yellow};
    text-decoration: underline;
    font-weight: 300;
  }
`;

export const S = {
  Container,
  Label,
  SubmitBtn,
  FormWrapper,
  TextContainer,
  PasswordInput,
  FogotPasswordLink,
  BottomContent,
  ContactUsBlock,
  LoginOptions: LoginOptionsContainer,
  LoginBox,
  LoginTitle,
  LoginDescription,
  LoginButton,
  FooterText
};
