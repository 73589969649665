import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import type { Theme } from 'styled-components';

import GlobalStyles from 'theme/GlobalStyles';
import theme from 'theme/theme';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      Black: string;
      White: string;
      MainGreen: string;
      OceanBlue: string;
      WhiteGreen: string;
      LightGreen: string;
      Orange: string;
      Blue: string;
      Purple: string;
      Pink: string;
      Yellow: string;
    };
    deviceSizes: {
      [key: string]: string;
    };
    [key: string]: any;
  }
}

// @ts-ignore
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* @ts-ignore */}
      <ThemeProvider theme={theme}>
        {/* @ts-ignore */}
        <GlobalStyles />
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
